'use strict';

(function ($, window, document) {

  $(function () {

    // Slider
    (function () {
      if ($('.o-page--top').length) {
        $('.js-slider').slick({
          fade: true,
          cssEase: 'linear',
          speed: 1000,
          autoplay: true,
          autoplaySpeed: 8000,
          pauseOnHover: false,
          pauseOnFocus: false,
          infinite: true,
          dots: true,
          dotsClass: 'c-slider__dots',
          arrows: false
        });
      }
    })();


    // Video slides
    (function () {
      if ($('.o-page--top').length) {
        $('.c-slider__item--video1').YTPlayer({
          ratio: 16 / 9,
          videoId: 'LSmgKRx5pBo',
          mute: true,
          width: $(window).width(),
          pauseOnScroll: false,
          repeat: true,
          fitToBackground: true,
          start: 0
        });
      }
    })();


    // Fade in slide overlay
    (function () {

      const slideOverlay = {

        el: {
          $text: $('.c-slider__item__text'),
          $slide: $('.c-slider__item')
        },

        config: {
          fadeDelayOverlay: 1000,
          fadeDelayText: 2500,
          fadeDuration: 700
        },

        init() {
          this.fadeInText();
          this.fadeInOverlay();
        },

        fadeInText() {
          const _this = this;

          setTimeout(function () {
            $(_this.el.$text).addClass('c-slider__item__text__active');
          }, _this.config.fadeDelayText);

        },

        fadeInOverlay() {
          const _this = this;

          setTimeout(function () {
            $(_this.el.$slide).addClass('c-slider__item__overlay');
          }, _this.config.fadeDelayOverlay);

        }

      };

      if ($('.o-page--top').length) {
        slideOverlay.init();
      }

    })();


    // Fade in slide overlay
    (function () {

      const cardTabs = {

        el: {
          $card: $('.c-card')
        },

        config: {
        },

        init() {
          this.clickHandler();
        },

        clickHandler() {
          const _this = this;

          this.el.$card.find('.c-card__info__tab').on('click', function () {
            const $parentCard = $(this).closest('.c-card');

            const targetContentID = _this.getTargetContent(this);
            _this.showContent(this, targetContentID, $parentCard);
            _this.switchCurrentTab(this, $parentCard);
          });
        },

        getTargetContent(tab) {
          return $(tab).attr('id').replace('js_tab', '#js_content');
        },

        showContent(tab, targetContentID, $parentCard) {
          $parentCard.find('.c-card__info__content').removeClass('c-card__info__content--active');
          $parentCard.find(targetContentID).addClass('c-card__info__content--active');
        },

        switchCurrentTab(tab, $parentCard) {
          $parentCard.find('.c-card__info__tab').removeClass('c-card__info__tab--active');
          $parentCard.find(tab).addClass('c-card__info__tab--active');
        }

      };

      if ($('.c-card').length) {
        cardTabs.init();
      }

    })();


    // Equal heights
    (function () {

      const equalHeights = {

        el: {
          $col: $('.js_equal-height')
        },

        init() {
          this.setEqualHeights();
        },

        setEqualHeights() {
          const _this = this;

          _this.el.$col.matchHeight();
        }

      };

      if ($('.js_equal-height').length) {
        equalHeights.init();
      }

    })();


    // Sticky global navigation
    (function () {

      const stickyNav = {

        el: {
          $header: $('.c-header'),
          stickyHeaderClass: 'c-header__sticky'
        },

        config: {
          stickyEnabled: false,
          stickyCreated: false,
          stickyHeight: 80,
          stickyHeightNarrow: 91
        },

        init() {
          this.scroll();
        },

        scroll() {
          const _this = this;
          let scrollPos;
          let stickyPos;

          $(window).scroll(function () {
            scrollPos = $(window).scrollTop();
            stickyPos = _this.el.$header.offset().top + _this.el.$header.outerHeight();

            if (scrollPos > stickyPos && _this.config.stickyEnabled !== true) {
              if (_this.config.stickyCreated !== true) {

                _this.createStickyNav();
                _this.slideDown();

                _this.config.stickyCreated = true;
              } else {
                _this.slideDown();
              }

              _this.config.stickyEnabled = true;
            }

            if (scrollPos < stickyPos && _this.config.stickyEnabled !== false) {
              _this.slideUp();

              _this.config.stickyEnabled = false;
            }

          });

        },

        createStickyNav() {
          this.el.$header.clone().addClass(this.el.stickyHeaderClass).prependTo('body');
        },

        slideDown() {
          $(`.${this.el.stickyHeaderClass}`).animate({
            top: '0'
          }, 500);
        },

        slideUp() {
          if (($(window).width()) > 950) {
            $(`.${this.el.stickyHeaderClass}`).animate({
              top: `-${this.config.stickyHeight}`
            }, 500);
          } else {
            $(`.${this.el.stickyHeaderClass}`).animate({
              top: `-${this.config.stickyHeightNarrow}`
            }, 500);
          }
        }

      };

      // Only enable the sticky dropdown navigation on the top page
      if (!$('.o-page--top').length && $('.c-header').length) {
        stickyNav.init();
      }

    })();


    // Instagram feed
    (function () {

      const userFeed = new Instafeed({
        get: 'user',
        limit: 4,
        resolution: 'low_resolution',
        userId: 2981385348,
        accessToken: '2981385348.1677ed0.206a1569cdf64c7191d4b0dd5b37e663',
        template: '<a class="c-footer-instagram__images__img" href="{{link}}" target="_blank"><img src="{{image}}" /></a>'
      });
      if ($('#instafeed').length) {
        userFeed.run();
      }

    })();


    // Page navigation
    (function () {

      const pageNav = {

        el: {
          $links: $('.js_links')
        },

        config: {
          offset: 20,
          animationSpeed: 700
        },

        init() {
          this.ckickHandler();
        },

        ckickHandler() {
          const _this = this;

          this.el.$links.on('click', 'li', function (e) {
            e.preventDefault();

            const target = $(this).find('a').attr('href');
            let headerHeight = 0;
            if ($('.c-header').css('display') !== 'none') {
              headerHeight = $('.c-header').height();
            }
            const offset = $(target).offset().top - _this.config.offset - headerHeight;

            $('html,body').animate({ scrollTop: offset }, _this.config.animationSpeed);

            return 0;
          });
        }

      };

      if ($('.js_links').length) {
        pageNav.init();
      }

    })();


    // Global navigation SP
    (function () {

      const siteNav = {

        el: {
          $toggle: $('.js_nav-toggle'),
          $nav: $('.js_nav'),
          $overlay: $('.js_nav-overlay')
        },

        config: {
        },

        init() {
          this.clickHandler();
        },

        clickHandler() {
          const _this = this;

          this.el.$toggle.on('click', function (e) {
            e.preventDefault();

            $(this).toggleClass('active');
            _this.toggleNav();
            _this.toggleOverlay();

            return 0;
          });
        },

        toggleNav() {
          this.el.$nav.toggleClass('active');
        },

        toggleOverlay() {
          this.el.$overlay.toggleClass('active');
        }

      };

      if ($('.js_nav-toggle').length) {
        siteNav.init();
      }

    })();


    // Set SP top page window height
    (function () {

      const topPageHeight = {

        init() {
          this.setHeight();
          this.resizeHandler();
        },

        setHeight() {
          const windowHeight = window.innerHeight;

          $('body').css('height', windowHeight);
        },

        resizeHandler() {
          const _this = this;

          $(window).on('resize', function () {
            _this.setHeight();
          });
        }

      };

      if ($('.o-page--top').length && $(window).width() < 768) {
        topPageHeight.init();
      }

    })();


    // Hide entries
    (function () {

      const entriesHandler = {

        el: {
          $entriesAll: $('.js_entry'),
          $entries: $('.js_entry:gt(2)'),
          $btnParent: $('.js_entries-reveal'),
          $btn: $('.js_entries-reveal a')
        },

        init() {
          this.hideEntries();
          this.showButton();
          this.clickHandler();
        },

        hideEntries() {
          this.el.$entries.hide();
        },

        showEntries() {
          this.el.$entries.show();
        },

        removeButton() {
          this.el.$btnParent.remove();
        },

        showButton() {
          if (this.el.$entriesAll.length > 3) {
            this.el.$btnParent.show();
          }
        },

        clickHandler() {
          const _this = this;

          _this.el.$btn.on('click', function (e) {
            e.preventDefault();

            _this.showEntries();
            _this.removeButton();

            return 0;
          });
        }

      };

      if ($('.js_entries').length) {
        entriesHandler.init();
      }

    })();


  });

})(window.jQuery, window, document);
